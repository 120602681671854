import React from 'react';

const NetworkHero = () => (
  <svg viewBox="0 0 800 500" className="w-full h-full">
    <defs>
      <linearGradient id="screenGlow" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#93c5fd" stopOpacity="0.2"/>
        <stop offset="100%" stopColor="#0369a1" stopOpacity="0.1"/>
      </linearGradient>
    </defs>

    {/* Connection Lines */}
    <g strokeWidth="2" stroke="#0369a1" opacity="0.2">
      {/* Left Top to Center */}
      <line 
        x1="180" y1="120" 
        x2="400" y2="120" 
        strokeDasharray="5,5"
        className="animate-dash"
      />
      {/* Right Top to Center */}
      <line 
        x1="620" y1="120" 
        x2="400" y2="120" 
        strokeDasharray="5,5"
        className="animate-dash"
      />
      {/* Center Vertical */}
      <line 
        x1="400" y1="120" 
        x2="400" y2="250" 
        strokeDasharray="5,5"
        className="animate-dash"
      />
      {/* Center to Bottom Left */}
      <line 
        x1="400" y1="250" 
        x2="180" y2="380" 
        strokeDasharray="5,5"
        className="animate-dash"
      />
      {/* Center to Bottom Right */}
      <line 
        x1="400" y1="250" 
        x2="620" y2="380" 
        strokeDasharray="5,5"
        className="animate-dash"
      />
    </g>

    {/* Top Left Device */}
    <g className="animate-fade-in">
      <rect 
        x="100" y="70"
        width="160" height="100" 
        rx="8" 
        fill="#fff" 
        stroke="#0369a1" 
        strokeWidth="2"
      />
      <rect 
        x="110" y="80"
        width="140" height="80" 
        rx="4" 
        fill="url(#screenGlow)"
      />
      {/* Notification */}
      <g className="animate-float">
        <circle cx="240" cy="90" r="8" fill="#0369a1"/>
        <text x="240" y="93" fontSize="12" fill="white" textAnchor="middle">1</text>
      </g>
    </g>

    {/* Top Right Device */}
    <g className="animate-fade-in">
      <rect 
        x="540" y="70"
        width="160" height="100" 
        rx="8" 
        fill="#fff" 
        stroke="#0369a1" 
        strokeWidth="2"
      />
      <rect 
        x="550" y="80"
        width="140" height="80" 
        rx="4" 
        fill="url(#screenGlow)"
      />
      {/* Notification */}
      <g className="animate-float">
        <circle cx="680" cy="90" r="8" fill="#0369a1"/>
        <text x="680" y="93" fontSize="12" fill="white" textAnchor="middle">2</text>
      </g>
    </g>

    {/* Center Device */}
    <g className="animate-fade-in">
      <rect 
        x="320" y="200"
        width="160" height="100" 
        rx="8" 
        fill="#fff" 
        stroke="#0369a1" 
        strokeWidth="2"
      />
      <rect 
        x="330" y="210"
        width="140" height="80" 
        rx="4" 
        fill="url(#screenGlow)"
      />
      {/* Activity Indicators */}
      <g className="animate-pulse">
        <circle cx="350" cy="250" r="4" fill="#0369a1" opacity="0.6"/>
        <circle cx="370" cy="250" r="4" fill="#0369a1" opacity="0.6"/>
        <circle cx="390" cy="250" r="4" fill="#0369a1" opacity="0.6"/>
      </g>
    </g>

    {/* Bottom Left Device */}
    <g className="animate-fade-in">
      <rect 
        x="100" y="330"
        width="160" height="100" 
        rx="8" 
        fill="#fff" 
        stroke="#0369a1" 
        strokeWidth="2"
      />
      <rect 
        x="110" y="340"
        width="140" height="80" 
        rx="4" 
        fill="url(#screenGlow)"
      />
      {/* Notification */}
      <g className="animate-float">
        <circle cx="240" cy="350" r="8" fill="#0369a1"/>
        <text x="240" y="353" fontSize="12" fill="white" textAnchor="middle">3</text>
      </g>
    </g>

    {/* Bottom Right Device */}
    <g className="animate-fade-in">
      <rect 
        x="540" y="330"
        width="160" height="100" 
        rx="8" 
        fill="#fff" 
        stroke="#0369a1" 
        strokeWidth="2"
      />
      <rect 
        x="550" y="340"
        width="140" height="80" 
        rx="4" 
        fill="url(#screenGlow)"
      />
      {/* Notification */}
      <g className="animate-float">
        <circle cx="680" cy="350" r="8" fill="#0369a1"/>
        <text x="680" y="353" fontSize="12" fill="white" textAnchor="middle">4</text>
      </g>
    </g>

    {/* Floating Particles */}
    {[...Array(6)].map((_, i) => (
      <circle 
        key={i}
        cx={300 + Math.random() * 200}
        cy={150 + Math.random() * 200}
        r="4"
        fill="#0369a1"
        opacity="0.2"
        className="animate-float"
      />
    ))}
  </svg>
);

export default NetworkHero;