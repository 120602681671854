import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Menu,
  X,
  Calculator,
  ArrowRight,
  CheckCircle
} from 'lucide-react';

const HowWeHelpPage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const aceProcess = [
    { letter: 'A', title: 'Assess', description: "We start by diving deep into understanding your business's objectives, hurdles, and financial landscape." },
    { letter: 'C', title: 'Customize', description: "We craft a tailored financial strategy that fits your unique business model, emphasizing process enhancements and risk management." },
    { letter: 'E', title: 'Execute', description: "With the strategy established, we implement the plan—streamlining structures, overseeing risk, and driving growth." }
  ];

  const packages = [
    {
      title: "CFO/Advisory",
      price: "Custom Pricing",
      features: [
        "Weekly Transaction Categorization",
        "Weekly Account Reconciliation",
        "Monthly Financial Reporting",
        "Chart of Accounts Customization",
        "Account Payable Management",
        "Accounts Receivable Management",
        "Payroll Assistance",
        "Budget Creation and Analysis",
        "KPI Monitoring and Reporting",
        "Cash Flow Forecasting",
        "Monthly Flux Analysis",
        "Operational Process Advisory"
      ],
      support: {
        strategic: "Monthly Strategic Planning",
        email: "24-Hour Response Time",
        phone: "24-Hour Response Time"
      }
    },
    {
      title: "Accountant",
      price: "Custom Pricing",
      features: [
        "Monthly Transaction Categorization",
        "Monthly Account Reconciliation",
        "Monthly Financial Reporting",
        "Chart of Accounts Customization",
        "Account Payable Management",
        "Accounts Receivable Management",
        "Budget Creation and Analysis",
        "KPI Monitoring and Reporting"
      ],
      support: {
        strategic: "Quarterly Strategic Planning",
        email: "48-Hour Response Time",
        phone: "48-Hour Response Time"
      }
    },
    {
      title: "Bookkeeper",
      price: "Custom Pricing",
      features: [
        "Quarterly Transaction Categorization",
        "Quarterly Account Reconciliation",
        "Quarterly Financial Reporting",
        "Chart of Accounts Customization"
      ],
      support: {
        strategic: "Quarterly Strategic Planning",
        email: "72-Hour Response Time",
        phone: "72-Hour Response Time"
      }
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="fixed w-full z-50 bg-white/80 backdrop-blur-md border-b">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-16 items-center">
            <div className="flex items-center space-x-2">
              <Calculator className="w-8 h-8 text-sky-700" />
              <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-sky-700 to-sky-900 bg-clip-text text-transparent">
                HRS Accounting
              </Link>
            </div>

            <div className="hidden md:flex items-center space-x-8">
              <Link to="/how-we-help" className="text-gray-600 hover:text-sky-700 transition-colors">How We Help</Link>
              <Link to="/insights" className="text-gray-600 hover:text-sky-700 transition-colors">Insights</Link>
              <a 
                href="https://calendly.com/contact-hrs/consultation" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="px-6 py-2.5 bg-sky-700 text-white rounded-lg hover:bg-sky-800 transition-colors"
              >
                Book a Consult
              </a>
            </div>

            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="md:hidden">
              {isMenuOpen ? <X /> : <Menu />}
            </button>
          </div>

          {/* Mobile menu */}
          {isMenuOpen && (
            <div className="md:hidden py-4">
              <Link to="/how-we-help" className="block py-2 text-gray-600">How We Help</Link>
              <Link to="/insights" className="block py-2 text-gray-600">Insights</Link>
              <a 
                href="https://calendly.com/contact-hrs/consultation" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="px-6 py-2.5 bg-sky-700 text-white rounded-lg hover:bg-sky-800 transition-colors"
              >
                Book a Consult
              </a>
            </div>
          )}
        </div>
      </nav>
      {/* Hero Section */}
      <section className="pt-32 pb-20 px-4">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-5xl font-serif mb-6">
            Comprehensive & reliable accounting with a
            <span className="text-sky-700 italic"> human-first focus.</span>
          </h1>
          <p className="text-xl text-gray-600 mb-12 max-w-3xl">
            Achieving success is easier with trusted expertise and personalized care. Our dedicated team provides custom solutions to empower your business at each stage.
          </p>
        </div>
      </section>

      {/* Growth Challenge Section */}
      <section className="py-20 px-4 bg-gray-100">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-4xl font-serif mb-8 text-sky-900">
           Achieving <span className="italic">success comes with its own set of challenges.</span>
          </h2>
          <p className="text-2xl font-semibold text-gray-700 mb-4 text-center">
            As your business expands, so do your accounting challenges, often at a faster pace.
          </p>
          <p className="text-lg text-gray-600 mb-12 text-center">
            Business growth brings new complexities in managing finances. It’s normal to feel overwhelmed as your operations scale, but hiring full-time financial experts can be costly.
          </p>
          <div className="bg-white p-8 rounded-xl shadow-xl text-center">
            <p className="text-lg text-gray-700">
                Imagine having complete financial clarity and control without straining your budget.
            </p>
          </div>
        </div>
      </section>

      {/* ACE Process Section */}
      <section className="py-20 px-4 bg-sky-900 text-white">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-4xl font-serif mb-12">Our ACE Process:</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {aceProcess.map((step, index) => (
              <div key={index} className="bg-sky-800/50 p-8 rounded-xl">
                <div className="text-4xl font-bold mb-4">{step.letter}</div>
                <h3 className="text-2xl mb-4">{step.title}</h3>
                <p className="text-sky-200">{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Service Packages Section */}
      <section className="py-20 px-4 bg-white">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-4xl font-serif mb-12 text-center">Our Service Packages</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {packages.slice().reverse().map((pkg, index) => (
              <div key={index} className="bg-gray-50 rounded-xl p-8 shadow-xl hover:shadow-2xl transition-shadow">
                <h3 className="text-2xl font-bold mb-4">{pkg.title}</h3>
                <p className="text-sky-700 font-bold mb-8">{pkg.price}</p>
                <ul className="space-y-4 mb-8">
                  {pkg.features.map((feature, i) => (
                    <li key={i} className="flex items-start space-x-3">
                      <CheckCircle className="w-6 h-6 text-sky-600 flex-shrink-0" />
                      <span>{feature}</span>
                    </li>
                  ))}
                </ul>
                <div className="border-t pt-6">
                  <h4 className="font-bold mb-4">Support & Access</h4>
                  <p className="mb-2">Strategic Planning: {pkg.support.strategic}</p>
                  <p className="mb-2">Email Support: {pkg.support.email}</p>
                  <p className="mb-2">Phone Support: {pkg.support.phone}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="py-20 px-4 bg-sky-900 text-white">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-4xl font-serif mb-8">Ready to Move Forward?</h2>
          <p className="text-xl mb-12">
            Our proven approach ensures you gain confidence and clarity in your financial processes.
          </p>
          <a 
            href="https://calendly.com/contact-hrs/consultation" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="group px-8 py-4 bg-white text-sky-900 rounded-xl hover:bg-gray-100 transition-all flex items-center"
            >
            Schedule Your Consultation
            <ArrowRight className="ml-2 group-hover:translate-x-1 transition-transform" />
            </a>

        </div>
      </section>
    </div>
  );
};

export default HowWeHelpPage;
