import React from 'react';

export const CashFlowIllustration = () => (
  <svg viewBox="0 0 800 450" className="w-full h-full">
    <defs>
      <linearGradient id="bgGradient2" x1="0" y1="0" x2="1" y2="1">
        <stop offset="0%" stopColor="#f0f9ff"/>
        <stop offset="100%" stopColor="#e0f2fe"/>
      </linearGradient>
      <linearGradient id="flowGradient" x1="0" y1="0" x2="1" y2="0">
        <stop offset="0%" stopColor="#0369a1"/>
        <stop offset="100%" stopColor="#0ea5e9"/>
      </linearGradient>
    </defs>
    
    <rect width="800" height="450" fill="url(#bgGradient2)"/>
    
    {/* Main Flow Diagram */}
    <g transform="translate(100, 100)">
      {/* Buildings/Nodes */}
      <g className="animate-fade-in">
        {[0, 1, 2].map((i) => (
          <g key={i} transform={`translate(${i * 200}, 0)`}>
            <rect 
              width="120" 
              height="160" 
              rx="8" 
              fill="#fff" 
              stroke="#0369a1" 
              strokeWidth="2"
            />
            <rect 
              x="20" 
              y="20" 
              width="80" 
              height="40" 
              rx="4" 
              fill="#0369a1" 
              opacity="0.1"
            />
            <rect 
              x="20" 
              y="70" 
              width="80" 
              height="70" 
              rx="4" 
              fill="#0369a1" 
              opacity="0.05"
            />
          </g>
        ))}
      </g>

      {/* Flow Arrows */}
      <g className="animate-draw">
        <path 
          d="M 120,80 C 170,80 150,80 200,80" 
          fill="none" 
          stroke="url(#flowGradient)" 
          strokeWidth="4"
          markerEnd="url(#arrowhead)"
        />
        <path 
          d="M 320,80 C 370,80 350,80 400,80" 
          fill="none" 
          stroke="url(#flowGradient)" 
          strokeWidth="4"
          markerEnd="url(#arrowhead)"
        />
      </g>

      {/* Flow Particles */}
      <g className="animate-float">
        {[0,1,2].map((i) => (
          <circle
            key={i}
            cx={160 + i * 200}
            cy={80}
            r="6"
            fill="#0369a1"
            opacity="0.6"
          />
        ))}
      </g>
    </g>

    {/* Bottom Stats */}
    <g transform="translate(100, 300)">
      {[0,1,2].map((i) => (
        <g key={i} transform={`translate(${i * 200}, 0)`}>
          <rect 
            width="160" 
            height="80" 
            rx="8" 
            fill="#fff" 
            stroke="#0369a1" 
            strokeWidth="2"
          />
          <text 
            x="80" 
            y="45" 
            fontSize="24" 
            fill="#0369a1" 
            textAnchor="middle"
          >
            ${(i + 1) * 25}k
          </text>
        </g>
      ))}
    </g>
  </svg>
);

export default CashFlowIllustration;