import axios from 'axios';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_STRAPI_URL || 'http://192.168.20.214:1337'}/api`,
});

export const getDynamicPosts = async () => {
  try {
    const response = await api.get('/blog-posts', {
      params: {
        populate: '*',
        sort: ['createdAt:desc'],
      },
    });

    console.log('Posts response:', response.data);

    if (!response.data?.data) {
      console.log('No data in response');
      return [];
    }

    return response.data.data.map(item => {
      const post = item;
      console.log('Processing post:', post);
      
      return {
        slug: `dynamic-${post.Slug || ''}`,
        title: post.Title || '',
        subtitle: post.Subtitle || '',
        date: post.Date ? new Date(post.Date).toLocaleDateString() : new Date(post.createdAt).toLocaleDateString(),
        readTime: post.ReadTime || '5 min read',
        excerpt: post.Excerpt || '',
        content: post.Content || '',
        illustration: post.Illustration?.data?.attributes?.url
          ? `${process.env.REACT_APP_STRAPI_URL}${post.Illustration.data.attributes.url}`
          : null,
        isDynamic: true
      };
    });
  } catch (error) {
    console.error('Error fetching dynamic posts:', error);
    return [];
  }
};

export const getDynamicPost = async (slug) => {
  try {
    const realSlug = slug.replace('dynamic-', '');
    console.log('Fetching post with slug:', realSlug);

    const response = await api.get('/blog-posts', {
      params: {
        filters: {
          Slug: {
            $eq: realSlug,
          },
        },
        populate: '*',
      },
    });

    console.log('Single post response:', response.data);

    if (!response.data?.data?.[0]) {
      console.log('No post found');
      return null;
    }

    // Get the raw post data
    const rawPost = response.data.data[0];
    console.log('Raw post data:', rawPost);

    return {
      slug: `dynamic-${rawPost.Slug}`,
      title: rawPost.Title || '',
      subtitle: rawPost.Subtitle || '',
      date: rawPost.Date ? new Date(rawPost.Date).toLocaleDateString() : new Date(rawPost.createdAt).toLocaleDateString(),
      readTime: rawPost.ReadTime || '5 min read',
      excerpt: rawPost.Excerpt || '',
      content: rawPost.Content || '',
      illustration: rawPost.Illustration?.data?.attributes?.url
        ? `${process.env.REACT_APP_STRAPI_URL}${rawPost.Illustration.data.attributes.url}`
        : null,
      isDynamic: true,
      relatedPosts: rawPost.related_posts?.data?.map(related => ({
        title: related.Title || '',
        description: related.Excerpt || '',
        link: `/blog/dynamic-${related.Slug || ''}`
      })) || []
    };
  } catch (error) {
    console.error('Error fetching dynamic post:', error);
    console.error('Error details:', error.response?.data);
    return null;
  }
};