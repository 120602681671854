import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Menu, X, Calculator } from 'lucide-react'; // Removed ArrowLeft and Home
import ReactMarkdown from 'react-markdown';
import { getDynamicPost } from '../services/strapi';
import { getStaticPost } from '../content/static-posts';

const BlogPost = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { slug } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        if (slug?.startsWith('dynamic-')) {
          const dynamicPost = await getDynamicPost(slug);
          if (dynamicPost) {
            setPost(dynamicPost);
            setLoading(false);
            return;
          }
        }

        const staticPost = getStaticPost(slug);
        if (staticPost) {
          setPost(staticPost);
          setLoading(false);
          return;
        }

        navigate('/insights');
      } catch (error) {
        console.error('Error in fetchPost:', error);
        navigate('/insights');
      }
    };

    fetchPost();
  }, [slug, navigate]);

  const renderContent = () => {
    if (post.isDynamic) {
      return (
        <ReactMarkdown
          className="prose prose-lg max-w-none"
          components={{
            // Styled components (same as before)
            h1: ({ node, children, ...props }) => (
              <h1 className="text-4xl font-serif mb-8" {...props}>{children}</h1>
            ),
            h2: ({ node, children, ...props }) => (
              <h2 className="text-3xl font-serif mb-6 text-sky-900" {...props}>{children}</h2>
            ),
            h3: ({ node, children, ...props }) => (
              <h3 className="text-2xl font-serif mb-4" {...props}>{children}</h3>
            ),
            p: ({ node, children, ...props }) => (
              <p className="mb-6 text-gray-700 leading-relaxed" {...props}>{children}</p>
            ),
            ul: ({ node, children, ...props }) => (
              <ul className="list-disc pl-6 mb-6 space-y-2" {...props}>{children}</ul>
            ),
            ol: ({ node, children, ...props }) => (
              <ol className="list-decimal pl-6 mb-6 space-y-2" {...props}>{children}</ol>
            ),
            li: ({ node, children, ...props }) => (
              <li className="text-gray-700" {...props}>{children}</li>
            ),
            blockquote: ({ node, children, ...props }) => (
              <blockquote className="border-l-4 border-sky-200 pl-4 my-6 italic text-gray-700" {...props}>{children}</blockquote>
            ),
            a: ({ node, children, ...props }) => (
              <a className="text-sky-700 hover:text-sky-900 underline" {...props}>{children}</a>
            ),
            pre: ({ node, children, ...props }) => (
              <pre className="bg-gray-50 rounded-lg p-4 overflow-x-auto mb-6" {...props}>{children}</pre>
            ),
            code: ({ node, inline, children, ...props }) => (
              inline ? <code className="bg-gray-100 rounded px-1 py-0.5" {...props}>{children}</code> :
              <code className="block" {...props}>{children}</code>
            ),
            hr: ({ node, ...props }) => (
              <hr className="my-8 border-gray-200" {...props} />
            ),
          }}
        >
          {post.content}
        </ReactMarkdown>
      );
    }
    return post.content;
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-sky-700"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="fixed w-full z-50 bg-white/80 backdrop-blur-md border-b">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-16 items-center">
            <div className="flex items-center space-x-2">
              <Calculator className="w-8 h-8 text-sky-700" />
              <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-sky-700 to-sky-900 bg-clip-text text-transparent">
                HRS Accounting
              </Link>
            </div>

            <div className="hidden md:flex items-center space-x-8">
              <Link to="/how-we-help" className="text-gray-600 hover:text-sky-700 transition-colors">How We Help</Link>
              <Link to="/insights" className="text-gray-600 hover:text-sky-700 transition-colors">Insights</Link>
              <a 
                href="https://calendly.com/contact-hrs/consultation" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="px-6 py-2.5 bg-sky-700 text-white rounded-lg hover:bg-sky-800 transition-colors"
              >
                Book a Consult
              </a>
            </div>

            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="md:hidden">
              {isMenuOpen ? <X /> : <Menu />}
            </button>
          </div>

          {/* Mobile menu */}
          {isMenuOpen && (
            <div className="md:hidden py-4">
              <Link to="/how-we-help" className="block py-2 text-gray-600">How We Help</Link>
              <Link to="/insights" className="block py-2 text-gray-600">Insights</Link>
              <button className="mt-4 w-full px-6 py-2.5 bg-sky-700 text-white rounded-lg">
                Book a Consult
              </button>
            </div>
          )}
        </div>
      </nav>

      {/* Hero Section */}
      <div className="bg-sky-900 text-white pt-24 pb-12">
        <div className="max-w-7xl mx-auto px-4">
          <h1 className="text-4xl md:text-5xl font-serif mb-6">{post.title}</h1>
          <div className="flex items-center space-x-4 text-sky-200">
            <span>{post.date}</span>
            <span>•</span>
            <span>{post.readTime}</span>
          </div>
        </div>
      </div>

      <article className="max-w-3xl mx-auto px-4 py-12">
        {/* Featured Image */}
        <div className="aspect-[16/9] bg-sky-50 rounded-xl mb-12 overflow-hidden">
          {post.isDynamic ? (
            post.illustration && (
              <img 
                src={post.illustration}
                alt={post.title}
                className="w-full h-full object-cover"
              />
            )
          ) : (
            <div className="w-full h-full">
              {post.illustration}
            </div>
          )}
        </div>

        {/* Content */}
        <div className="prose prose-lg max-w-none">
          {renderContent()}
        </div>

        {/* Related Posts */}
        {post.relatedPosts && post.relatedPosts.length > 0 && (
          <div className="mt-16 pt-8 border-t">
            <h2 className="text-2xl font-serif mb-6">Related Articles</h2>
            <div className="grid md:grid-cols-2 gap-6">
              {post.relatedPosts.map((relatedPost, index) => (
                <Link 
                  key={index}
                  to={relatedPost.link}
                  className="p-6 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors"
                >
                  <h3 className="font-semibold mb-2">{relatedPost.title}</h3>
                  <p className="text-gray-600">{relatedPost.description}</p>
                </Link>
              ))}
            </div>
          </div>
        )}
      </article>
    </div>
  );
};

export default BlogPost;
