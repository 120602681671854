import React from 'react';

export const FinancialStrategyIllustration = () => (
  <svg viewBox="0 0 800 450" className="w-full h-full">
    <defs>
      <linearGradient id="bgGradient1" x1="0" y1="0" x2="1" y2="1">
        <stop offset="0%" stopColor="#f0f9ff"/>
        <stop offset="100%" stopColor="#e0f2fe"/>
      </linearGradient>
    </defs>
    
    <rect width="800" height="450" fill="url(#bgGradient1)"/>
    
    {/* Main Dashboard */}
    <g transform="translate(100, 100)">
      <rect 
        width="300" 
        height="200" 
        rx="10" 
        fill="#fff" 
        stroke="#0369a1" 
        strokeWidth="2"
      />
      
      {/* Graph */}
      <g transform="translate(30, 40)">
        <path 
          d="M 0,100 Q 60,20 120,80 T 240,40" 
          fill="none" 
          stroke="#0369a1" 
          strokeWidth="3"
          className="animate-draw"
        />
        {/* Data Points */}
        <circle cx="60" cy="60" r="6" fill="#0369a1"/>
        <circle cx="120" cy="80" r="6" fill="#0369a1"/>
        <circle cx="180" cy="50" r="6" fill="#0369a1"/>
      </g>
    </g>

    {/* Documents */}
    <g transform="translate(500, 150)">
      <rect 
        width="200" 
        height="150" 
        rx="8" 
        fill="#fff" 
        stroke="#0369a1" 
        strokeWidth="2"
        className="animate-float"
      />
      {[0,1,2,3].map((i) => (
        <rect
          key={i}
          x="20"
          y={20 + i * 30}
          width="160"
          height="10"
          rx="2"
          fill="#0369a1"
          opacity="0.1"
        />
      ))}
    </g>

    {/* Floating Elements */}
    <g className="animate-float">
      <circle cx="400" cy="100" r="20" fill="#0369a1" opacity="0.1"/>
      <circle cx="450" cy="80" r="15" fill="#0369a1" opacity="0.1"/>
      <circle cx="380" cy="150" r="25" fill="#0369a1" opacity="0.1"/>
    </g>
  </svg>
);

export default FinancialStrategyIllustration;