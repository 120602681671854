import { financialStrategyPost } from './financial-strategy';
import { cashFlowPost } from './cash-flow-management';
import { growthScalingPost } from './growth-and-scaling';

export const staticPosts = [
  financialStrategyPost,
  cashFlowPost,
  growthScalingPost
];

export const getStaticPost = (slug) => {
  return staticPosts.find(post => post.slug === slug);
};