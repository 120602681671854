import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Removed ArrowLeft
import { Menu, X, Calculator } from 'lucide-react'; // Keep only what is needed
import { getDynamicPosts } from '../services/strapi';
import { staticPosts } from '../content/static-posts';

const BlogListing = () => {
  const [dynamicPosts, setDynamicPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const fetchPosts = async () => {
      const fetchedPosts = await getDynamicPosts();
      console.log("Fetched dynamic posts in BlogListing:", fetchedPosts); // Verify data here
      setDynamicPosts(fetchedPosts);
      setLoading(false);
    };

    fetchPosts();
  }, []);

  // Combine static and dynamic posts, sort by date
  const allPosts = [...staticPosts, ...dynamicPosts].sort((a, b) =>
    new Date(b.date) - new Date(a.date)
  );
  console.log("All posts to render (static + dynamic):", allPosts); // Log combined posts

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-sky-700"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="fixed w-full z-50 bg-white/80 backdrop-blur-md border-b">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-16 items-center">
            <div className="flex items-center space-x-2">
              <Calculator className="w-8 h-8 text-sky-700" />
              <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-sky-700 to-sky-900 bg-clip-text text-transparent">
                HRS Accounting
              </Link>
            </div>

            <div className="hidden md:flex items-center space-x-8">
              <Link to="/how-we-help" className="text-gray-600 hover:text-sky-700 transition-colors">How We Help</Link>
              <Link to="/insights" className="text-gray-600 hover:text-sky-700 transition-colors">Insights</Link>
              <a 
                href="https://calendly.com/contact-hrs/consultation" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="px-6 py-2.5 bg-sky-700 text-white rounded-lg hover:bg-sky-800 transition-colors"
              >
                Book a Consult
              </a>
            </div>

            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="md:hidden">
              {isMenuOpen ? <X /> : <Menu />}
            </button>
          </div>

          {/* Mobile menu */}
          {isMenuOpen && (
            <div className="md:hidden py-4">
              <Link to="/how-we-help" className="block py-2 text-gray-600">How We Help</Link>
              <Link to="/insights" className="block py-2 text-gray-600">Insights</Link>
              <a 
                href="https://calendly.com/contact-hrs/consultation" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="px-6 py-2.5 bg-sky-700 text-white rounded-lg hover:bg-sky-800 transition-colors"
              >
                Book a Consult
              </a>
            </div>
          )}
        </div>
      </nav>

      {/* Hero Section */}
      <div className="bg-sky-900 text-white pt-24 pb-12">
        <div className="max-w-7xl mx-auto px-4">
          <h1 className="text-4xl md:text-5xl font-serif mb-6">
            Financial Insights & Resources
          </h1>
          <p className="text-xl text-sky-100">
            Expert insights to help you make better financial decisions
          </p>
        </div>
      </div>

      {/* Blog Posts Grid */}
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {allPosts.map((post) => (
            <Link
              key={post.slug}
              to={`/blog/${post.slug}`} // Ensure this path matches the route in App.js
              className="group"
            >
              <div className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-xl transition-shadow">
                <div className="aspect-[16/9] bg-sky-50 overflow-hidden">
                  <div className="w-full h-full">
                    {post.illustration}
                  </div>
                </div>
                <div className="p-6">
                  <div className="flex items-center space-x-4 text-sm text-gray-600 mb-3">
                    <span>{post.date}</span>
                    <span>•</span>
                    <span>{post.readTime}</span>
                  </div>
                  <h2 className="text-xl font-bold mb-2 text-gray-900 group-hover:text-sky-700 transition-colors">
                    {post.title}
                  </h2>
                  <p className="text-gray-600">{post.excerpt}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogListing;
