import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import BlogListing from './components/BlogListing';
import BlogPost from './components/BlogPost';
import HowWeHelpPage from './components/HowWeHelpPage';
import AnalyticsProvider from './components/AnalyticsProvider';

function App() {
  return (
    <AnalyticsProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/insights" element={<BlogListing />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
          <Route path="/how-we-help" element={<HowWeHelpPage />} />
        </Routes>
      </Router>
    </AnalyticsProvider>
  );
}

export default App;