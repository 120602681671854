import React, { useEffect } from 'react';

const AnalyticsProvider = ({ children }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-M2X8QQ7EEF';
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-M2X8QQ7EEF');

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return children;
};

export default AnalyticsProvider;