import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { 
  Menu, 
  X, 
  Calculator,
  ArrowRight,
  CheckCircle 
} from 'lucide-react';
import NetworkHero from './NetworkHero';
import { staticPosts } from '../content/static-posts';
import computerImage from '../assets/computer3.webp'; // Adjust the path as needed

const Homepage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Navigation */}
      <nav className="fixed w-full z-50 bg-white/80 backdrop-blur-md border-b">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between h-16 items-center">
            <div className="flex items-center space-x-2">
              <Calculator className="w-8 h-8 text-sky-700" />
              <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-sky-700 to-sky-900 bg-clip-text text-transparent">
                HRS Accounting
              </Link>
            </div>

            <div className="hidden md:flex items-center space-x-8">
              <Link to="/how-we-help" className="text-gray-600 hover:text-sky-700 transition-colors">How We Help</Link>
              <Link to="/insights" className="text-gray-600 hover:text-sky-700 transition-colors">Insights</Link>
              <a 
                href="https://calendly.com/contact-hrs/consultation" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="px-6 py-2.5 bg-sky-700 text-white rounded-lg hover:bg-sky-800 transition-colors"
              >
                Book a Consult
              </a>
            </div>

            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="md:hidden">
              {isMenuOpen ? <X /> : <Menu />}
            </button>
          </div>

          {/* Mobile menu */}
          {isMenuOpen && (
            <div className="md:hidden py-4">
              <Link to="/how-we-help" className="block py-2 text-gray-600">How We Help</Link>
              <Link to="/insights" className="block py-2 text-gray-600">Insights</Link>
              <a 
                href="https://calendly.com/contact-hrs/consultation" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="px-6 py-2.5 bg-sky-700 text-white rounded-lg hover:bg-sky-800 transition-colors"
              >
                Book a Consult
              </a>
            </div>
          )}
        </div>
      </nav>
      {/* Hero Section */}
      <section className="pt-32 pb-20 px-1">
      <div className="max-w-6xl mx-auto grid md:grid-cols-2 gap-4 items-center">

          <div>
            <h1 className="text-5xl font-serif mb-8">
              Expert Financial
              <span className="block text-sky-700 italic">Leadership On Demand</span>
            </h1>
            <div className="space-y-4 text-gray-600">
              <div className="flex items-center space-x-3">
                <div className="w-5 h-px bg-sky-700" />
                <span className="text-lg">Strategic Financial Planning</span>
              </div>
              <div className="flex items-center space-x-3">
                <div className="w-5 h-px bg-sky-700" />
                <span className="text-lg">CFO Advisory Services</span>
              </div>
              <div className="flex items-center space-x-3">
                <div className="w-5 h-px bg-sky-700" />
                <span className="text-lg">Growth & Optimization</span>
              </div>
            </div>
          </div>
          <div className="shadow-xl">
            <img 
              src={computerImage} 
              alt="Computer" 
              className="mx-auto h-auto rounded-lg" 
            />
          </div>
        </div>
      </section>

      {/* Solution Section */}
      <section className="py-20 px-4 bg-black text-white" id="solutions">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-4xl font-serif mb-8">The good news is there is a solution!</h2>
          <p className="text-xl text-gray-300 mb-12">
            It may simply be time to level up to a Virtual CFO (vCFO)
          </p>

          <div className="grid md:grid-cols-2 gap-16">
            <div className="space-y-8">
              {[
                "Maintain updated financials with customized reporting",
                "Take advantage of strategic opportunities",
                "Understand your cashflow deeply",
                "Access funding with confidence"
              ].map((item, i) => (
                <div key={i} className="flex items-start space-x-4">
                  <CheckCircle className="w-6 h-6 text-sky-400 flex-shrink-0 mt-1" />
                  <span>{item}</span>
                </div>
              ))}
            </div>

            <div className="bg-gray-900 p-8 rounded-xl -mt-20">
              <h3 className="text-2xl mb-6">With a vCFO, you get:</h3>
              <p className="text-gray-300 mb-6">
                All the advantages of having a full-time CFO without the cost of adding another C-level to your staff.
              </p>
              <p className="text-gray-300 mb-6">
                Replace surprise fees with predictable fixed fees.
              </p>
              <Link to="/how-we-help" className="group px-8 py-4 bg-sky-600 text-white rounded-xl hover:bg-sky-700 transition-all flex items-center">How We Help</Link>
              
            </div>
          </div>
        </div>
      </section>

      {/* Fearless Approach Section */}
      <section className="py-20 px-4 bg-white" id="approach">
        <div className="max-w-7xl mx-auto grid md:grid-cols-2 gap-16 items-center">
          <div className="bg-gray-100 p-8 rounded-xl">
            <NetworkHero />
          </div>
          <div>
            <h2 className="text-4xl font-serif mb-6 text-sky-800">
              We embrace fearlessness.
            </h2>
            <p className="text-xl text-gray-600 mb-8">
              Don't expect a traditional consulting firm. Expect to be heard. Understood. 
              And sometimes even challenged. We implement innovative solutions alongside you and your team.
            </p>
            <a href="https://calendly.com/your-calendly-link" target="_blank" rel="noopener noreferrer">
              <button className="group px-8 py-4 bg-sky-600 text-white rounded-xl hover:bg-sky-700 transition-all flex items-center">
                Book a Consult
              <ArrowRight className="ml-2 group-hover:translate-x-1 transition-transform" />
             </button>
            </a>

          </div>
        </div>
      </section>

      {/* Insights Section */}
      <section className="py-20 px-4 bg-sky-900 text-white">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-4xl font-serif text-center mb-12">
            Financial Insights & Resources
          </h2>
          
          <div className="grid md:grid-cols-3 gap-8">
            {staticPosts.slice(0, 3).map((post) => (
              <Link 
                key={post.slug}
                to={`/blog/${post.slug}`}
                className="group bg-sky-800/50 rounded-xl overflow-hidden hover:bg-sky-800/70 transition-all"
              >
                <div className="aspect-[16/9] bg-sky-700/30">
                  {post.illustration}
                </div>
                <div className="p-6">
                  <div className="flex items-center space-x-4 text-sm text-sky-200 mb-3">
                    <span>{post.date}</span>
                    <span>•</span>
                    <span>{post.readTime}</span>
                  </div>
                  <h3 className="text-xl font-bold mb-2 group-hover:text-sky-300 transition-colors">
                    {post.title}
                  </h3>
                  <p className="text-sky-200">{post.excerpt}</p>
                </div>
              </Link>
            ))}
          </div>

          <div className="text-center mt-12">
            <Link 
              to="/insights" 
              className="inline-block px-8 py-3 bg-white text-sky-900 rounded-xl hover:bg-gray-100 transition-all"
            >
              View All Insights
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Homepage;