import React from 'react';

export const GrowthScalingIllustration = () => (
  <svg viewBox="0 0 800 450" className="w-full h-full">
    <defs>
      <linearGradient id="bgGradient3" x1="0" y1="0" x2="1" y2="1">
        <stop offset="0%" stopColor="#f0f9ff"/>
        <stop offset="100%" stopColor="#e0f2fe"/>
      </linearGradient>
      <linearGradient id="growthGradient" x1="0" y1="1" x2="1" y2="0">
        <stop offset="0%" stopColor="#0369a1"/>
        <stop offset="100%" stopColor="#0ea5e9"/>
      </linearGradient>
    </defs>
    
    <rect width="800" height="450" fill="url(#bgGradient3)"/>
    
    {/* Main Graph Area */}
    <g transform="translate(100, 50)">
      {/* Background Grid */}
      {[0,1,2,3,4].map((i) => (
        <g key={i}>
          <line 
            x1="0" 
            y1={i * 80} 
            x2="600" 
            y2={i * 80} 
            stroke="#0369a1" 
            strokeWidth="1" 
            opacity="0.1"
          />
          <text 
            x="-20" 
            y={i * 80} 
            fill="#0369a1" 
            opacity="0.5" 
            textAnchor="end" 
            dominantBaseline="middle"
          >
            {100 - i * 20}%
          </text>
        </g>
      ))}

      {/* Growth Curve */}
      <path 
        d="M 0,320 Q 200,280 300,200 T 600,40" 
        fill="none" 
        stroke="url(#growthGradient)" 
        strokeWidth="4"
        className="animate-draw"
      />

      {/* Data Points */}
      {[
        [0, 320],
        [200, 280],
        [300, 200],
        [450, 120],
        [600, 40]
      ].map(([x, y], i) => (
        <g key={i} className="animate-pulse">
          <circle 
            cx={x} 
            cy={y} 
            r="8" 
            fill="#fff" 
            stroke="#0369a1" 
            strokeWidth="2"
          />
          <circle 
            cx={x} 
            cy={y} 
            r="16" 
            fill="#0369a1" 
            opacity="0.1"
          />
        </g>
      ))}
    </g>

    {/* Bottom Stats */}
    <g transform="translate(100, 400)">
      {['Q1', 'Q2', 'Q3', 'Q4'].map((q, i) => (
        <text
          key={i}
          x={i * 200}
          y="0"
          fill="#0369a1"
          opacity="0.7"
          textAnchor="middle"
        >
          {q}
        </text>
      ))}
    </g>
  </svg>
);

export default GrowthScalingIllustration;